export interface IVisitScheduleFilter {
  company_id: string;
  product_interests: string[];
  users: string[];
  selling_points: string[];
  enterprises: string[];
  average_wage_start: string;
  average_wage_end: string;
  fgts_start: string;
  fgts_end: string;
  potential_purchase_start: string;
  potential_purchase_end: string;
  start_visit_date: string;
  end_visit_date: string;
  start_visited_date: string;
  end_visited_date: string;
  order_by: VisitScheduleOrderByEnum;
  group_by: VisitScheduleGroupByEnum;
  search_by: VisitScheduleSearchByEnum;

  generateXls(): any;

  generatePdf(): any;
}

export enum VisitScheduleGroupByEnum {
  USER = 'USER',
  ENTERPRISE = 'ENTERPRISE',
  PRODUCT_INTERESTS = 'PRODUCT_INTERESTS',
}

export enum VisitScheduleOrderByEnum {
  AVERAGE_WAGE = 'AVERAGE_WAGE',
  FGTS = 'FGTS',
  POTENTIAL_PURCHASE = 'POTENTIAL_PURCHASE',
}

export enum VisitScheduleSearchByEnum {
  SCHEDULE = 'SCHEDULE',
  VISITED = 'VISITED',
}
