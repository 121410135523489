
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IReportCard } from '../interfaces/report-card.interfaces';

@Component
export default class ReportCardComponent extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  private readonly reportContent!: IReportCard;

  private openDialog() {
    this.$emit('input', this.reportContent.action);
  }
}
