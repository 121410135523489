import {
  ClientsRegistredFilterGroupBy,
  ClientsRegistredFilterOrderBy,
  IClientsRegistredFilter,
} from '../interfaces/clients-registred-filter.interface';

export class ClientsRegistredFilter implements IClientsRegistredFilter {
  public company_id: string;

  public users: string[];

  public start_date: string;

  public end_date: string;

  public average_wage_start: string;

  public average_wage_end: string;

  public fgts_start: string;

  public fgts_end: string;

  public order_by: ClientsRegistredFilterOrderBy | string;

  public group_by: ClientsRegistredFilterGroupBy | string;

  constructor() {
    this.company_id = '';
    this.users = [];
    this.start_date = '';
    this.end_date = '';
    this.average_wage_start = '';
    this.average_wage_end = '';
    this.fgts_start = '';
    this.fgts_end = '';
    this.order_by = '';
    this.group_by = ClientsRegistredFilterGroupBy.USER;
  }

  format() {
    return {
      order_by: this.order_by || undefined,
      group_by: this.group_by || undefined,
      average_wage_start: this.average_wage_start || undefined,
      average_wage_end: this.average_wage_end || undefined,
      fgts_start: this.fgts_start || undefined,
      fgts_end: this.fgts_end || undefined,
      company_id: this.company_id ? this.company_id : undefined,
      users: this.users.length ? this.users : undefined,
      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
    };
  }
}
