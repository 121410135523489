import { IContactOriginFilter } from '../interfaces/contact-origin-filter.interface';

export class ContactOriginFilter implements IContactOriginFilter {
  public company_id: string;

  public channels: string[];

  public users: string[];

  public selling_points: string[];

  public start_date: string;

  public end_date: string;

  constructor() {
    this.company_id = '';
    this.channels = [];
    this.selling_points = [];
    this.users = [];
    this.start_date = '';
    this.end_date = '';
  }

  format() {
    return {
      company_id: this.company_id ? this.company_id : undefined,
      channels: this.channels.length ? this.channels : undefined,
      selling_points: this.selling_points.length ? this.selling_points : undefined,
      users: this.users.length ? this.users : undefined,
      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
    };
  }
}
