
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import moment from '@/services/moment/moment.service';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import rulesService from '@/services/rules.service';
import { SellingPoint } from '@/modules/Administrative/modules/SellingPoints/entities/selling-point.entity';
import sellingPointsService from '@/modules/Administrative/modules/SellingPoints/services/selling-points.service';
import downloadService from '@/services/download.service';
import Attachment from '@/modules/shared/entities/attachment.entity';
import { ProductInterest } from '@/modules/Administrative/modules/ProductInterests/entities/product-interest.entity';
import productInterestService from '@/modules/Administrative/modules/ProductInterests/services/product-interest.service';
import enterpriseService from '@/modules/Administrative/modules/Enterprises/services/enterprise.service';
import { Enterprise } from '@/modules/Administrative/modules/Enterprises/entities/enterprise.entity';
import { IProductInterestFilter } from '../interfaces/product-interest-filter.interface';
import { ProductInterestFilter } from '../entities/product-interest-filter.entity';
import reportService from '../services/report.service';
import { IUnitsSoldFilter } from '../interfaces/units-sold-filter.interface';
import { UnitsSoldFilter } from '../entities/units-sold-filter.entity';

@Component({
  components: {
    DatePicker,
  },
  computed: { ...mapGetters(['user']) },
})
export default class UnitsSoldDialog extends Vue {
  private companies: Company[] = [];

  private user!: any;

  public $refs!: any;

  private enterprises: Enterprise[] = [];

  private users: User[] = [];

  private sellingPoints: SellingPoint[] = [];

  private filter: IUnitsSoldFilter = new UnitsSoldFilter();

  private rules = {
    required: rulesService.required,
  };

  private close() {
    this.$emit('close');
  }

  private async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  public icon(itemsSelect: any[], allItemsToAdd: any[]) {
    const isAllSelected = itemsSelect.length === allItemsToAdd.length;
    if (isAllSelected) return 'mdi-close-box';
    if (itemsSelect.length > 0 && !isAllSelected) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  public selectAllSellingPoints() {
    const isAllSelected = this.filter.selling_points.length === this.sellingPoints.length;

    if (isAllSelected) {
      this.filter.selling_points = [];
      return;
    }
    this.filter.selling_points = this.sellingPoints.map((it: any) => it.id);
  }

  public selectAllEnterprise() {
    const isAllSelected = this.filter.enterprises.length === this.enterprises.length;

    if (isAllSelected) {
      this.filter.enterprises = [];
      return;
    }
    this.filter.enterprises = this.enterprises.map((it: any) => it.id);
  }

  public async getAllEnterprises() {
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(this.filter.company_id);
    this.enterprises = enterprises.map((it: any) => new Enterprise(it));
  }

  private generateReport() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .unitsSoldGenerateReport(this.filter.format())
      .then(({ data }: any) => {
        const winUrl = URL.createObjectURL(new Blob([data.template], { type: 'text/html' }));

        window.open(winUrl, '__blank');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  private generateReportExcel() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .unitsSoldGenerateReportExcel({ ...this.filter.format(), export: 'xls' })
      .then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octetstream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${new Date().getTime()}.xls`;
        link.click();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  private allowedDaysStart(value: string) {
    if (this.filter.end_date) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.end_date).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  private allowedDaysEnd(value: string) {
    if (this.filter.start_date) {
      return (
        moment(value, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.filter.start_date).format('YYYY-MM-DD'),
        ) && moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  private resetForm() {
    this.user = [];
    this.sellingPoints = [];
    this.filter = new UnitsSoldFilter();
  }

  private setCompanyId(companyId: string) {
    this.resetForm();
    this.filter.company_id = companyId;
    this.getAllEnterprises();
    this.getSellingPointsByCompanyId();
  }

  private async getSellingPointsByCompanyId() {
    if (!this.filter.company_id) return;
    const sellingPoints: Company[] = await sellingPointsService.getSellingPointByCompanyId(
      this.filter.company_id,
    );
    this.sellingPoints = sellingPoints.map(sellingPoint => new SellingPoint(sellingPoint));
  }

  private created() {
    this.filter.company_id = this.user.company_id;
    this.getCompanies();
    this.getAllEnterprises();
    this.getSellingPointsByCompanyId();
  }
}
