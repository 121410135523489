
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import moment from '@/services/moment/moment.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import rulesService from '@/services/rules.service';

import statusService from '@/modules/Administrative/modules/Status/services/status.service';
import reportService from '../services/report.service';
import { ISalesFunnelConvertionFilter } from '../interfaces/sales-funnel-convertion-filter.interface';
import { SalesFunnelConvertionFilter } from '../entities/sales-funnel-convertion-filter.entity';

@Component({
  components: {
    DatePicker,
  },
  computed: { ...mapGetters(['user']) },
})
export default class SalesFunnelConvertionReportComponent extends Vue {
  public companies: Company[] = [];

  public user!: any;

  public $refs!: any;

  public status: Status[] = [];

  public filter: ISalesFunnelConvertionFilter = new SalesFunnelConvertionFilter();

  public rules = {
    required: rulesService.required,
  };

  public close() {
    this.$emit('close');
  }

  public async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  public generateReport() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .salesFunnelConversion(this.filter.format())
      .then(({ data }: any) => {
        const winUrl = URL.createObjectURL(new Blob([data.template], { type: 'text/html' }));

        window.open(winUrl, '__blank');
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public generateReportExcel() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .salesFunnelConversionExcel({ ...this.filter.format(), export: 'xls' })
      .then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octetstream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${new Date().getTime()}.xls`;
        link.click();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public allowedDaysStart(value: string) {
    if (this.filter.end_date) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.end_date).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  public allowedDaysEnd(value: string) {
    if (this.filter.start_date) {
      return (
        moment(value, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.filter.start_date).format('YYYY-MM-DD'),
        ) && moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  resetForm() {
    this.filter.status_id = '';
    this.filter.start_date = '';
    this.filter.end_date = '';
  }

  public setCompanyId(companyId: string) {
    this.resetForm();
    this.filter.company_id = companyId;
  }

  async getAllStatus() {
    const status = await statusService.getStatusByCompanyId(this.filter.company_id);
    this.status = status;
  }

  public created() {
    this.filter.company_id = this.user.company_id;
    this.getCompanies();
    this.getAllStatus();
  }
}
