import { IProductInterestFilter } from '../interfaces/product-interest-filter.interface';

export class ProductInterestFilter implements IProductInterestFilter {
  public company_id: string;

  public users: string[];

  public selling_points: string[];

  public product_interests: string[];

  public start_date: string;

  public end_date: string;

  constructor() {
    this.company_id = '';
    this.users = [];
    this.selling_points = [];
    this.product_interests = [];
    this.start_date = '';
    this.end_date = '';
  }

  format() {
    return {
      company_id: this.company_id ? this.company_id : undefined,
      users: this.users.length ? this.users : undefined,
      selling_points: this.selling_points.length ? this.selling_points : undefined,
      product_interests: this.product_interests.length ? this.product_interests : undefined,
      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
    };
  }
}
