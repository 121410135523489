import {
  IVisitScheduleFilter,
  VisitScheduleGroupByEnum,
  VisitScheduleOrderByEnum,
  VisitScheduleSearchByEnum,
} from '../interfaces/visit-schedule-filter.interface';
import reportService from '../services/report.service';

export class VisitScheduleFilter implements IVisitScheduleFilter {
  public company_id: string;

  public product_interests: string[];

  public users: string[];

  public selling_points: string[];

  public enterprises: string[];

  public average_wage_start: string;

  public average_wage_end: string;

  public fgts_start: string;

  public fgts_end: string;

  public potential_purchase_start: string;

  public potential_purchase_end: string;

  public start_visit_date: string;

  public end_visit_date: string;

  public start_visited_date: string;

  public end_visited_date: string;

  public order_by: VisitScheduleOrderByEnum;

  public search_by: VisitScheduleSearchByEnum;

  public group_by: VisitScheduleGroupByEnum;

  constructor(data: any = {}) {
    this.company_id = data.company_id || '';
    this.product_interests = data.product_interests || [];
    this.users = data.users || [];
    this.selling_points = data.selling_points || [];
    this.enterprises = data.enterprises || [];
    this.average_wage_start = data.average_wage_start;
    this.average_wage_end = data.average_wage_end;
    this.potential_purchase_start = data.potential_purchase_start;
    this.potential_purchase_end = data.potential_purchase_end;
    this.fgts_start = data.fgts_start;
    this.fgts_end = data.fgts_end;
    this.start_visit_date = data.start_date || '';
    this.end_visit_date = data.end_date || '';
    this.start_visited_date = data.start_date || '';
    this.end_visited_date = data.end_date || '';
    this.order_by = data.order_by || VisitScheduleOrderByEnum.AVERAGE_WAGE;
    this.search_by = data.search_by || VisitScheduleSearchByEnum.SCHEDULE;
    this.group_by = data.group_by || VisitScheduleGroupByEnum.USER;
  }

  generateXls(): any {
    return reportService.ordersVisitExcel({ ...this.format(), export: 'xls' });
  }

  generatePdf(): any {
    return reportService.ordersVisit(this.format());
  }

  format(): any {
    return {
      ...this,
      is_visit: this.search_by === VisitScheduleSearchByEnum.VISITED ? 1 : 0,
      selling_points: this.selling_points.length ? this.selling_points : undefined,
      enterprises: this.enterprises.length ? this.enterprises : undefined,
      product_interests: this.product_interests.length ? this.product_interests : undefined,
      users: this.users.length ? this.users : undefined,
      start_visit_date: this.start_visit_date || undefined,
      end_visit_date: this.end_visit_date || undefined,
      start_visited_date: this.start_visited_date || undefined,
      end_visited_date: this.end_visited_date || undefined,
    };
  }
}
