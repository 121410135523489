import { IUnitsSoldFilter, UnitsSoldGroupByEnum } from '../interfaces/units-sold-filter.interface';

export class UnitsSoldFilter implements IUnitsSoldFilter {
  public company_id: string;

  public enterprises: string[];

  public selling_points: string[];

  public groupBy: UnitsSoldGroupByEnum;

  public start_date: string;

  public end_date: string;

  constructor() {
    this.company_id = '';
    this.enterprises = [];
    this.selling_points = [];
    this.start_date = '';
    this.groupBy = UnitsSoldGroupByEnum.ENTERPRISE;
    this.end_date = '';
  }

  format() {
    return {
      company_id: this.company_id ? this.company_id : undefined,
      group_by: this.groupBy ? this.groupBy : undefined,
      enterprises: this.enterprises.length ? this.enterprises : undefined,
      selling_points: this.selling_points.length ? this.selling_points : undefined,

      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
    };
  }
}
