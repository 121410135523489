export interface IClientsRegistredFilter {
  company_id: string;
  users: string[];
  start_date: string;
  end_date: string;
  average_wage_start: string;
  average_wage_end: string;
  fgts_start: string;
  fgts_end: string;
  order_by?: ClientsRegistredFilterOrderBy | string;
  group_by?: ClientsRegistredFilterOrderBy | string;

  format(): any;
}

export enum ClientsRegistredFilterOrderBy {
  AVERAGE_WAGE = 'AVERAGE_WAGE',
  FGTS = 'FGTS',
  USER = 'USER',
}

export enum ClientsRegistredFilterGroupBy {
  USER = 'USER',
}
