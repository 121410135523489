
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import moment from '@/services/moment/moment.service';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import rulesService from '@/services/rules.service';
import { SellingPoint } from '@/modules/Administrative/modules/SellingPoints/entities/selling-point.entity';
import sellingPointsService from '@/modules/Administrative/modules/SellingPoints/services/selling-points.service';
import downloadService from '@/services/download.service';
import Attachment from '@/modules/shared/entities/attachment.entity';
import { ProductInterest } from '@/modules/Administrative/modules/ProductInterests/entities/product-interest.entity';
import productInterestService from '@/modules/Administrative/modules/ProductInterests/services/product-interest.service';
import { IProductInterestFilter } from '../interfaces/product-interest-filter.interface';
import { ProductInterestFilter } from '../entities/product-interest-filter.entity';
import reportService from '../services/report.service';

@Component({
  components: {
    DatePicker,
  },
  computed: { ...mapGetters(['user']) },
})
export default class ProductInterestReportDialog extends Vue {
  public companies: Company[] = [];

  public user!: any;

  public $refs!: any;

  public users: User[] = [];

  public sellingPoints: SellingPoint[] = [];

  public interestProducts: ProductInterest[] = [];

  public filter: IProductInterestFilter = new ProductInterestFilter();

  public rules = {
    required: rulesService.required,
  };

  public close() {
    this.$emit('close');
  }

  public icon(itemsSelect: any[], allItemsToAdd: any[]) {
    const isAllSelected = itemsSelect.length === allItemsToAdd.length;
    if (isAllSelected) return 'mdi-close-box';
    if (itemsSelect.length > 0 && !isAllSelected) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  public selectAllUsers() {
    const isAllSelected = this.filter.users.length === this.users.length;

    if (isAllSelected) {
      this.filter.users = [];
      return;
    }
    this.filter.users = this.users.map((it: any) => it.id);
  }

  public selectAllSellingPoints() {
    const isAllSelected = this.filter.selling_points.length === this.sellingPoints.length;

    if (isAllSelected) {
      this.filter.selling_points = [];
      return;
    }
    this.filter.selling_points = this.sellingPoints.map((it: any) => it.id);
  }

  public selectProductInterests() {
    const isAllSelected = this.filter.product_interests.length === this.interestProducts.length;

    if (isAllSelected) {
      this.filter.product_interests = [];
      return;
    }
    this.filter.product_interests = this.interestProducts.map((it: any) => it.id);
  }

  public async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  public async getAllUsers() {
    if (!this.filter.company_id) return;
    const users = await userService.getAllUsers({
      params: {
        company_id: this.filter.company_id,
      },
    });
    this.users = users.map((it: any) => new User(it));
  }

  public generateReport() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .productInterestGenerateReport(this.filter.format())
      .then(({ data }: any) => {
        const winUrl = URL.createObjectURL(new Blob([data.template], { type: 'text/html' }));

        window.open(winUrl, '__blank');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public generateReportExcel() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .productInterestGenerateReportExcel({ ...this.filter.format(), export: 'xls' })
      .then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octetstream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${new Date().getTime()}.xls`;
        link.click();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public allowedDaysStart(value: string) {
    if (this.filter.end_date) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.end_date).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  public allowedDaysEnd(value: string) {
    if (this.filter.start_date) {
      return (
        moment(value, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.filter.start_date).format('YYYY-MM-DD'),
        ) && moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  public resetForm() {
    this.user = [];
    this.sellingPoints = [];
    this.interestProducts = [];
    this.filter.users = [];
    this.filter.selling_points = [];
    this.filter.product_interests = [];
    this.filter.start_date = '';
    this.filter.end_date = '';
  }

  public setCompanyId(companyId: string) {
    this.resetForm();
    this.filter.company_id = companyId;
    this.getAllUsers();
    this.getSellingPointsByCompanyId();
    this.getInterestProducts();
  }

  public async getSellingPointsByCompanyId() {
    if (!this.filter.company_id) return;
    const sellingPoints: Company[] = await sellingPointsService.getSellingPointByCompanyId(
      this.filter.company_id,
    );
    this.sellingPoints = sellingPoints.map(sellingPoint => new SellingPoint(sellingPoint));
  }

  public async getInterestProducts() {
    if (!this.filter.company_id) return;
    const interestProducts: ProductInterest[] = await productInterestService.getAllProductInterests(
      { params: { company_id: this.filter.company_id } },
    );
    this.interestProducts = interestProducts.map((it: any) => new ProductInterest(it));
  }

  public created() {
    this.filter.company_id = this.user.company_id;
    this.getCompanies();
    this.getAllUsers();
    this.getSellingPointsByCompanyId();
    this.getInterestProducts();
  }
}
