
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Channel } from '@/modules/Clients/components/form/entities/channel.entity';
import moment from '@/services/moment/moment.service';
import { Enterprise } from '@/modules/Administrative/modules/Enterprises/entities/enterprise.entity';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import rulesService from '@/services/rules.service';
import { SellingPoint } from '@/modules/Administrative/modules/SellingPoints/entities/selling-point.entity';
import enterpriseService from '@/modules/Administrative/modules/Enterprises/services/enterprise.service';
import reportService from '../services/report.service';

import { IUnitByEnterpriseFilter } from '../interfaces/unit-by-enterprise-filter.interface';
import { UnitByEnterpriseFilter } from '../entities/unit-by-enterprise-filter.entity';

@Component({
  components: {
    DatePicker,
  },
  computed: { ...mapGetters(['user']) },
})
export default class UnitByEnterpriseDialog extends Vue {
  public companies: Company[] = [];

  public user!: any;

  private enterprises: Enterprise[] = [];

  public $refs!: any;

  public filter: IUnitByEnterpriseFilter = new UnitByEnterpriseFilter();

  public rules = {
    required: rulesService.required,
  };

  public close() {
    this.$emit('close');
  }

  public icon(itemsSelect: any[], allItemsToAdd: any[]) {
    const isAllSelected = itemsSelect.length === allItemsToAdd.length;
    if (isAllSelected) return 'mdi-close-box';
    if (itemsSelect.length > 0 && !isAllSelected) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  public selectAllEnterprise() {
    const isAllSelected = this.filter.enterprises.length === this.enterprises.length;

    if (isAllSelected) {
      this.filter.enterprises = [];
      return;
    }
    this.filter.enterprises = this.enterprises.map((it: any) => it.id);
  }

  public async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  public async getAllEnterprises(companyId: string) {
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(companyId);
    this.enterprises = enterprises.map((it: any) => new Enterprise(it));
  }

  public generateReport() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .unitByEnterprise(this.filter.format())
      .then(({ data }: any) => {
        const winUrl = URL.createObjectURL(new Blob([data.template], { type: 'text/html' }));

        window.open(winUrl, '__blank');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public generateReportExcel() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .unitByEnterpriseExcel({ ...this.filter.format(), export: 'xls' })
      .then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octetstream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${new Date().getTime()}.xls`;
        link.click();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public allowedDaysStart(value: string) {
    if (this.filter.end_date) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.end_date).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  public allowedDaysEnd(value: string) {
    if (this.filter.start_date) {
      return (
        moment(value, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.filter.start_date).format('YYYY-MM-DD'),
        ) && moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  public setCompanyId(companyId: string) {
    this.filter.enterprises = [];
    this.filter.start_date = '';
    this.filter.end_date = '';
    this.filter.company_id = companyId;
    this.getAllEnterprises(companyId);
  }

  public created() {
    this.filter.company_id = this.user.company_id;
    this.getCompanies();
    this.getAllEnterprises(this.filter.company_id);
  }
}
