
import { Component, Vue } from 'vue-property-decorator';

import { IReportCard } from './interfaces/report-card.interfaces';
import { ReportTypes } from './report-types/report-types.enum';
import ReportCardComponent from './components/report-card.component.vue';
import ProductInterestReportDialog from './report-dialogs-filter/product-interest-report.dialog.vue';
import ClientsRegistredDialog from './report-dialogs-filter/clients-registred.dialog.vue';
import ContactOriginDialog from './report-dialogs-filter/contact-origin.dialog.vue';
import UnitByEnterpriseDialog from './report-dialogs-filter/unit-by-enterprise.dialog.vue';
import UnitsSoldDialog from './report-dialogs-filter/units-sold.dialog.vue';
import CancellationReasonsDialog from './report-dialogs-filter/cancellation-reasons.dialog.vue';
import DistractReasonDialog from './report-dialogs-filter/distract-reason.dialog.vue';
import SalesFunnelConvertionReport from './report-dialogs-filter/sales-funnel-convertion-report.vue';
import SalesFunnelReportComponent from './report-dialogs-filter/sales-funnel-report.vue';
import VisitScheduleReport from './report-dialogs-filter/visit-schedule.vue';
import ReminderCallDialog from './report-dialogs-filter/reminder-call.dialog.vue';

@Component({
  components: {
    ProductInterestReportDialog,
    ReportCardComponent,
    ClientsRegistredDialog,
    ContactOriginDialog,
    UnitByEnterpriseDialog,
    UnitsSoldDialog,
    CancellationReasonsDialog,
    DistractReasonDialog,
    SalesFunnelConvertionReport,
    SalesFunnelReportComponent,
  },
})
export default class ReportWrapperComponent extends Vue {
  public reportTypeSelected?: ReportTypes | string = '';

  public showDialog: boolean = false;

  public close() {
    this.showDialog = false;
  }

  public get getDialogByType() {
    const dialogs: any = {
      [ReportTypes.PRODUCT_INTEREST]: ProductInterestReportDialog,
      [ReportTypes.REGISTERED_CUSTOMERS]: ClientsRegistredDialog,
      [ReportTypes.CONTACT_ORIGIN]: ContactOriginDialog,
      [ReportTypes.UNIT_BY_ENTERPRISE]: UnitByEnterpriseDialog,
      [ReportTypes.UNITS_SOLD]: UnitsSoldDialog,
      [ReportTypes.CANCELLATION_REASON]: CancellationReasonsDialog,
      [ReportTypes.DISTRACT_REASON]: DistractReasonDialog,
      [ReportTypes.SALES_FUNNEL_CONVERTION]: SalesFunnelConvertionReport,
      [ReportTypes.SALES_FUNNEL]: SalesFunnelReportComponent,
      [ReportTypes.VISIT_SCHEDULE]: VisitScheduleReport,
      [ReportTypes.REMINDER_CALL]: ReminderCallDialog,
    };
    return this.reportTypeSelected ? dialogs[this.reportTypeSelected] : '';
  }

  public reports: IReportCard[] = [
    {
      title: 'Produto de interesse',
      subtitle: 'Relatório',
      description: 'Tenha acesso aos produtos que seus clientes mais buscam.',
      action: ReportTypes.PRODUCT_INTEREST,
      image: '/assets/images/reports/product-interest-target/product-interest-target.svg',
    },
    {
      title: 'Clientes cadastrados',
      subtitle: 'Relatório',
      description: 'Tenha acesso aos clientes cadastrados separados por cada corretor.',
      action: ReportTypes.REGISTERED_CUSTOMERS,
      image: '/assets/images/reports/register-customers/register-costumers.svg',
    },
    {
      title: 'Origem do contato',
      subtitle: 'Relatório',
      description: 'Tenha acesso de onde os seus clientes estão chegando separados por canal.',
      action: ReportTypes.CONTACT_ORIGIN,
      image: '/assets/images/reports/contact-origin/contact-origin.svg',
    },
    {
      title: 'Unidades por empreendimento',
      subtitle: 'Relatório',
      description: 'Aqui será apresentado as unidades por empreendimento ou por situação. ',
      action: ReportTypes.UNIT_BY_ENTERPRISE,
      image: '/assets/images/reports/unit-by-enterprise/unit-by-enterprise.svg',
    },
    {
      title: 'Unidades vendidas',
      subtitle: 'Relatório',
      description:
        'Aqui será apresentado todas as unidades vendidas agrupadas por empreendimento ou usuário. ',
      action: ReportTypes.UNITS_SOLD,
      image: '/assets/images/reports/units-sold/units-sold.svg',
    },
    {
      title: 'Motivos de cancelamento',
      subtitle: 'Relatório',
      description:
        'Acesse os motivos que seus clientes estão desistindo das unidades, separados pelo motivo ou por usuário.',
      action: ReportTypes.CANCELLATION_REASON,
      image: '/assets/images/reports/cancellation-reason/cancellation-reason.svg',
    },
    {
      title: 'Motivos de distrato',
      subtitle: 'Relatório',
      description:
        'Acesse os motivos que seus clientes estão distratando, separados pelo motivo ou por usuário.',
      action: ReportTypes.DISTRACT_REASON,
      image: '/assets/images/reports/distract-reason/distract-reason.svg',
    },
    {
      title: 'Conversão do Funil de vendas',
      subtitle: 'Relatório',
      description:
        'Saiba como está a sua conversão de vendas e quantas propostas você está gerando por mês.',
      action: ReportTypes.SALES_FUNNEL_CONVERTION,
      image: '/assets/images/reports/sales-funnel/sales-funnel-convertion.svg',
    },
    {
      title: 'Funil de vendas',
      subtitle: 'Relatório',
      description: 'Saiba como está seu funil de vendas separado por usuários e status',
      action: ReportTypes.SALES_FUNNEL,
      image: '/assets/images/reports/sales-funnel/sales-funnel.svg',
    },
    {
      title: 'Relatório de visitas',
      subtitle: 'Relatório',
      description: 'Verifique os agendamentos de visitas e as que foram realizadas.',
      action: ReportTypes.VISIT_SCHEDULE,
      image: '/assets/images/reports/visit-schedule/visit-schedule.svg',
    },
    {
      title: 'Relatório de contatos agendados',
      subtitle: 'Relatório',
      description: 'Verifique os agendamentos de contatos e veja as agendadas/realizadas',
      action: ReportTypes.REMINDER_CALL,
      image: '/assets/images/reports/reminder-call/reminder-call.svg',
    },
  ];

  public openDialog(type: ReportTypes) {
    this.reportTypeSelected = type;
    this.showDialog = true;
  }
}
