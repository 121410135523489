export interface IReminderCallFilter {
  company_id: string;
  product_interests: string[];
  users: string[];
  selling_points: string[];
  enterprises: string[];
  average_wage_start: string;
  average_wage_end: string;
  fgts_start: string;
  fgts_end: string;
  potential_purchase_start: string;
  potential_purchase_end: string;
  start_reminder_date: string;
  end_reminder_date: string;
  start_called_date: string;
  end_called_date: string;
  order_by: ReminderCallOrderByEnum;
  group_by: ReminderCallGroupByEnum;
  search_by: ReminderCallSearchByEnum;

  generateXls(): any;

  generatePdf(): any;
}

export enum ReminderCallGroupByEnum {
  USER = 'USER',
  ENTERPRISE = 'ENTERPRISE',
  PRODUCT_INTERESTS = 'PRODUCT_INTERESTS',
}

export enum ReminderCallOrderByEnum {
  AVERAGE_WAGE = 'AVERAGE_WAGE',
  FGTS = 'FGTS',
  POTENTIAL_PURCHASE = 'POTENTIAL_PURCHASE',
}

export enum ReminderCallSearchByEnum {
  SCHEDULE = 'SCHEDULE',
  CALLED = 'CALLED',
}
