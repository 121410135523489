import { IUnitByEnterpriseFilter } from '../interfaces/unit-by-enterprise-filter.interface';

export enum EnterpriseGroupByEnum {
  ENTERPRISE = 'ENTERPRISE',
  COMMERCIAL_STOCK = 'COMMERCIAL_STOCK',
}

export class UnitByEnterpriseFilter implements IUnitByEnterpriseFilter {
  public company_id: string;

  public enterprises: string[];

  public groupBy: EnterpriseGroupByEnum;

  public start_date: string;

  public end_date: string;

  constructor() {
    this.company_id = '';
    this.enterprises = [];
    this.groupBy = EnterpriseGroupByEnum.ENTERPRISE;
    this.start_date = '';
    this.end_date = '';
  }

  format() {
    return {
      company_id: this.company_id ? this.company_id : undefined,
      enterprises: this.enterprises.length ? this.enterprises : undefined,
      group_by: this.groupBy ? this.groupBy : EnterpriseGroupByEnum.ENTERPRISE,
      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
    };
  }
}
