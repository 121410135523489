import { AxiosResponse } from 'axios';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { Reason } from '../entities/reason.entity';

class ReasonService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: Reason) {
    return http.post('/reason', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: Reason) {
    return http.put(`/reason/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(reasonId: string) {
    return http.delete(`/reason/${reasonId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllReasons(company_id?: string) {
    return http
      .get('/reason', {
        params: {
          company_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getAllReasonsPaginated() {
    return http
      .get('/reason', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getReasonById(id: string) {
    return http.get(`/reason/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getReasonByType(company_id?: string, type?: string) {
    return http
      .get(`/reason/type/${type}`, {
        params: {
          company_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new ReasonService(simplePaginationService);
