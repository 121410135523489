export interface IUnitsSoldFilter {
  company_id: string;
  selling_points: string[];
  enterprises: string[];
  groupBy: UnitsSoldGroupByEnum;
  start_date: string;
  end_date: string;

  format(): any;
}

export enum UnitsSoldGroupByEnum {
  USER = 'USER',
  ENTERPRISE = 'ENTERPRISE',
}
