export interface IDistractReasonFilter {
  company_id: string;
  reasons: string[];
  selling_points: string[];
  groupBy: DistractReasonGroupByEnum;
  start_date: string;
  end_date: string;

  format(): any;
}

export enum DistractReasonGroupByEnum {
  USER = 'USER',
  REASON = 'REASON',
}
