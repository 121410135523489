
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import moment from '@/services/moment/moment.service';
import { ProductInterest } from '@/modules/Administrative/modules/ProductInterests/entities/product-interest.entity';
import productInterestService from '@/modules/Administrative/modules/ProductInterests/services/product-interest.service';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import rulesService from '@/services/rules.service';
import { SellingPoint } from '@/modules/Administrative/modules/SellingPoints/entities/selling-point.entity';
import sellingPointsService from '@/modules/Administrative/modules/SellingPoints/services/selling-points.service';
import { Enterprise } from '@/modules/Administrative/modules/Enterprises/entities/enterprise.entity';
import enterpriseService from '@/modules/Administrative/modules/Enterprises/services/enterprise.service';
import { ClientsRegistredFilterOrderBy } from '../interfaces/clients-registred-filter.interface';
import { VisitScheduleFilter } from '../entities/visit-schedule-filter.entity';
import { IReminderCallFilter } from '../interfaces/reminder-call-filter.interface';
import { RemiderCallFilter } from '../entities/remider-call-filter.entity';

@Component({
  components: {
    DatePicker,
  },
  computed: { ...mapGetters(['user']) },
})
export default class ReminderCallDialog extends Vue {
  public companies: Company[] = [];

  public user!: any;

  public interestProducts: ProductInterest[] = [];

  public orderByTypes = [
    { value: ClientsRegistredFilterOrderBy.AVERAGE_WAGE, text: 'Renda' },
    { value: ClientsRegistredFilterOrderBy.FGTS, text: 'FGTS' },
  ];

  public moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  public $refs!: any;

  public showAverageWageBox: boolean = false;

  public showFgtsValueBox: boolean = false;

  public showPotentialPurchase: boolean = false;

  public enterprises: Enterprise[] = [];

  public users: User[] = [];

  public sellingPoints: SellingPoint[] = [];

  public filter: IReminderCallFilter = new RemiderCallFilter();

  public rules = {
    required: rulesService.required,
  };

  public selectAllUsers() {
    const isAllSelected = this.filter.users.length === this.users.length;

    if (isAllSelected) {
      this.filter.users = [];
      return;
    }
    this.filter.users = this.users.map((it: any) => it.id);
  }

  public icon(itemsSelect: any[], allItemsToAdd: any[]) {
    const isAllSelected = itemsSelect.length === allItemsToAdd.length;
    if (isAllSelected) return 'mdi-close-box';
    if (itemsSelect.length > 0 && !isAllSelected) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  public resetAverageWageBox(event: boolean) {
    if (!event) {
      this.filter.average_wage_start = '';
      this.filter.average_wage_end = '';
    }
  }

  public resetFgtsValueBox(event: boolean) {
    if (!event) {
      this.filter.fgts_start = '';
      this.filter.fgts_end = '';
    }
  }

  public resetPotentialPurchase(event: boolean) {
    if (!event) {
      this.filter.potential_purchase_start = '';
      this.filter.potential_purchase_end = '';
    }
  }

  public close() {
    this.$emit('close');
  }

  public async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map((company: Company) => new Company(company));
  }

  public selectAllEnterprise() {
    const isAllSelected = this.filter.enterprises.length === this.enterprises.length;

    if (isAllSelected) {
      this.filter.enterprises = [];
      return;
    }
    this.filter.enterprises = this.enterprises.map((it: any) => it.id);
  }

  public selectAllSellingPoints() {
    const isAllSelected = this.filter.selling_points.length === this.sellingPoints.length;

    if (isAllSelected) {
      this.filter.selling_points = [];
      return;
    }
    this.filter.selling_points = this.sellingPoints.map((it: any) => it.id);
  }

  public async getAllUsers(companyId: string) {
    const users = await userService.getAllUsers({
      params: {
        company_id: companyId,
      },
    });
    this.users = users.map((it: any) => new User(it));
  }

  public selectProductInterests() {
    const isAllSelected = this.filter.product_interests.length === this.interestProducts.length;

    if (isAllSelected) {
      this.filter.product_interests = [];
      return;
    }
    this.filter.product_interests = this.interestProducts.map((it: any) => it.id);
  }

  public async getInterestProducts() {
    if (!this.filter.company_id) return;
    const interestProducts: ProductInterest[] = await productInterestService.getAllProductInterests(
      { params: { company_id: this.filter.company_id } },
    );
    this.interestProducts = interestProducts.map((it: any) => new ProductInterest(it));
  }

  public generateReport() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    this.filter
      .generatePdf()
      .then(({ data }: any) => {
        const winUrl = URL.createObjectURL(new Blob([data.template], { type: 'text/html' }));

        window.open(winUrl, '__blank');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public generateReportExcel() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    this.filter
      .generateXls()
      .then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octetstream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${new Date().getTime()}.xls`;
        link.click();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public async getAllEnterprises() {
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(this.filter.company_id);
    this.enterprises = enterprises.map((it: any) => new Enterprise(it));
  }

  public allowedDaysStartCalled(value: string) {
    if (this.filter.end_called_date) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.end_called_date).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  public allowedDaysEndCalled(value: string) {
    if (this.filter.start_called_date) {
      return (
        moment(value, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.filter.start_called_date).format('YYYY-MM-DD'),
        ) && moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  resetForm() {
    this.filter = new RemiderCallFilter();
  }

  cleanDate() {
    this.filter.start_reminder_date = '';
    this.filter.end_reminder_date = '';
    this.filter.start_called_date = '';
    this.filter.end_called_date = '';
  }

  public setCompanyId(companyId: string) {
    this.resetForm();
    this.filter.company_id = companyId;
    this.getAllUsers(companyId);
    this.getSellingPointsByCompanyId();
    this.getInterestProducts();
    this.getAllEnterprises();
  }

  public async getSellingPointsByCompanyId() {
    const sellingPoints: Company[] = await sellingPointsService.getSellingPointByCompanyId(
      this.filter.company_id,
    );
    this.sellingPoints = sellingPoints.map(sellingPoint => new SellingPoint(sellingPoint));
  }

  public created() {
    this.filter.company_id = this.user.company_id;
    this.getCompanies();
    this.getAllUsers(this.filter.company_id);
    this.getSellingPointsByCompanyId();
    this.getInterestProducts();
    this.getAllEnterprises();
  }
}
