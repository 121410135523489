export interface ISalesFunnelFilter {
  company_id: string;
  groupBy: SalesFunnelGroupByEnum;
  start_date: string;
  selling_points: string[];
  users: string[];
  end_date: string;
  status_id: string;

  format(): any;
}

export enum SalesFunnelGroupByEnum {
  PRODUCT_INTEREST = 'PRODUCT_INTEREST',
  USER = 'USER',
  STATUS = 'STATUS',
}
