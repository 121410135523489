export enum ReportTypes {
  PRODUCT_INTEREST = 'PRODUCT_INTEREST',
  REGISTERED_CUSTOMERS = 'REGISTERED_CUSTOMERS',
  CONTACT_ORIGIN = 'CONTACT_ORIGIN',
  UNIT_BY_ENTERPRISE = 'UNIT_BY_ENTERPRISE',
  UNITS_SOLD = 'UNITS_SOLD',
  CANCELLATION_REASON = 'CANCELLATION_REASON',
  DISTRACT_REASON = 'DISTRACT_REASON',
  SALES_FUNNEL_CONVERTION = 'SALES_FUNNEL_CONVERTION',
  SALES_FUNNEL = 'SALES_FUNNEL',
  VISIT_SCHEDULE = 'VISIT_SCHEDULE',
  REMINDER_CALL = 'REMINDER_CALL',
}
