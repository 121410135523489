export interface ICancellationReasonFilter {
  company_id: string;
  reasons: string[];
  selling_points: string[];
  groupBy: CancellationReasonGroupByEnum;
  start_date: string;
  end_date: string;

  format(): any;
}

export enum CancellationReasonGroupByEnum {
  USER = 'USER',
  REASON = 'REASON',
}
