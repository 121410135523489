import {
  ISalesFunnelFilter,
  SalesFunnelGroupByEnum,
} from '../interfaces/sales-funnel-filter.interface';

export class SalesFunnelFilter implements ISalesFunnelFilter {
  public company_id: string;

  public groupBy: SalesFunnelGroupByEnum;

  public start_date: string;

  public selling_points: string[];

  public users: string[];

  public end_date: string;

  public status_id: string;

  constructor() {
    this.company_id = '';
    this.groupBy = SalesFunnelGroupByEnum.PRODUCT_INTEREST;
    this.start_date = '';
    this.status_id = '';
    this.end_date = '';
    this.selling_points = [];
    this.users = [];
  }

  format() {
    return {
      group_by: this.groupBy ? this.groupBy : undefined,
      company_id: this.company_id ? this.company_id : undefined,
      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
      status_id: this.status_id ? this.status_id : undefined,
      selling_points: this.selling_points.length ? this.selling_points : undefined,
      users: this.users.length ? this.users : undefined,
    };
  }
}
