import {
  IReminderCallFilter,
  ReminderCallGroupByEnum,
  ReminderCallOrderByEnum,
  ReminderCallSearchByEnum,
} from '../interfaces/reminder-call-filter.interface';
import reportService from '../services/report.service';

export class RemiderCallFilter implements IReminderCallFilter {
  public company_id: string;

  public product_interests: string[];

  public users: string[];

  public selling_points: string[];

  public enterprises: string[];

  public average_wage_start: string;

  public average_wage_end: string;

  public potential_purchase_start: string;

  public potential_purchase_end: string;

  public fgts_start: string;

  public fgts_end: string;

  public start_reminder_date: string;

  public end_reminder_date: string;

  public start_called_date: string;

  public end_called_date: string;

  public order_by: ReminderCallOrderByEnum;

  public group_by: ReminderCallGroupByEnum;

  public search_by: ReminderCallSearchByEnum;

  constructor(data: any = {}) {
    this.company_id = data.company_id || '';
    this.product_interests = data.product_interests || [];
    this.users = data.users || [];
    this.selling_points = data.selling_points || [];
    this.enterprises = data.enterprises || [];
    this.average_wage_start = data.average_wage_start;
    this.average_wage_end = data.average_wage_end;
    this.potential_purchase_start = data.potential_purchase_start;
    this.potential_purchase_end = data.potential_purchase_end;
    this.fgts_start = data.fgts_start;
    this.fgts_end = data.fgts_end;
    this.start_reminder_date = data.start_reminder_date;
    this.end_reminder_date = data.end_reminder_date;
    this.start_called_date = data.start_called_date;
    this.end_called_date = data.end_called_date;
    this.order_by = data.order_by || ReminderCallOrderByEnum.AVERAGE_WAGE;
    this.search_by = data.search_by || ReminderCallSearchByEnum.SCHEDULE;
    this.group_by = data.group_by || ReminderCallGroupByEnum.USER;
  }

  generateXls(): any {
    return reportService.reminderCalledExcel({ ...this.format(), export: 'xls' });
  }

  generatePdf(): any {
    return reportService.reminderCalled(this.format());
  }

  format(): any {
    return {
      ...this,
      is_called: this.search_by === ReminderCallSearchByEnum.CALLED ? 1 : 0,
      selling_points: this.selling_points.length ? this.selling_points : undefined,
      enterprises: this.enterprises.length ? this.enterprises : undefined,
      product_interests: this.product_interests.length ? this.product_interests : undefined,
      users: this.users.length ? this.users : undefined,
      start_reminder_date: this.start_reminder_date || undefined,
      end_reminder_date: this.end_reminder_date || undefined,
      start_called_date: this.start_called_date || undefined,
      end_called_date: this.end_called_date || undefined,
    };
  }
}
