import store from '@/store';

export class Channel {
  public id: string;

  public description: string;

  public icon: string;

  public main: boolean = false;

  public company_id: string;

  constructor(data: any = {}) {
    this.id = data.id || '';
    this.description = data.description || '';
    this.company_id = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.icon = data.icon || '';
    this.main = data.main;
  }
}
