export interface ISalesFunnelConvertionFilter {
  company_id: string;
  groupBy: SalesFunnelConvertionGroupByEnum;
  start_date: string;
  end_date: string;
  status_id: string;

  format(): any;
}

export enum SalesFunnelConvertionGroupByEnum {
  PRODUCT_INTEREST = 'PRODUCT_INTEREST',
  USER = 'USER',
  STATUS = 'STATUS',
}
