import {
  ISalesFunnelConvertionFilter,
  SalesFunnelConvertionGroupByEnum,
} from '../interfaces/sales-funnel-convertion-filter.interface';

export class SalesFunnelConvertionFilter implements ISalesFunnelConvertionFilter {
  public company_id: string;

  public groupBy: SalesFunnelConvertionGroupByEnum;

  public start_date: string;

  public end_date: string;

  public status_id: string;

  constructor() {
    this.company_id = '';
    this.groupBy = SalesFunnelConvertionGroupByEnum.PRODUCT_INTEREST;
    this.start_date = '';
    this.status_id = '';
    this.end_date = '';
  }

  format() {
    return {
      group_by: this.groupBy ? this.groupBy : undefined,
      company_id: this.company_id ? this.company_id : undefined,
      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
      status_id: this.status_id ? this.status_id : undefined,
    };
  }
}
