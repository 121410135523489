
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import moment from '@/services/moment/moment.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import { Reason } from '@/modules/Administrative/modules/Reasons/entities/reason.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';

import DatePicker from '@/components/date-picker/date-picker.component.vue';
import reasonService from '@/modules/Administrative/modules/Reasons/services/reason.service';
import rulesService from '@/services/rules.service';
import { SellingPoint } from '@/modules/Administrative/modules/SellingPoints/entities/selling-point.entity';
import sellingPointsService from '@/modules/Administrative/modules/SellingPoints/services/selling-points.service';

import reportService from '../services/report.service';

import { IDistractReasonFilter } from '../interfaces/distract-reason-filter.interface';
import { DistractReasonFilter } from '../entities/distract-reason-filter.entity';

@Component({
  components: {
    DatePicker,
  },
  computed: { ...mapGetters(['user']) },
})
export default class DistractReasonDialogComponent extends Vue {
  public companies: Company[] = [];

  public user!: any;

  public $refs!: any;

  public reasons: Reason[] = [];

  public sellingPoints: SellingPoint[] = [];

  public filter: IDistractReasonFilter = new DistractReasonFilter();

  public rules = {
    required: rulesService.required,
  };

  public close() {
    this.$emit('close');
  }

  public icon(itemsSelect: any[], allItemsToAdd: any[]) {
    const isAllSelected = itemsSelect.length === allItemsToAdd.length;
    if (isAllSelected) return 'mdi-close-box';
    if (itemsSelect.length > 0 && !isAllSelected) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  public selectAllSellingPoints() {
    const isAllSelected = this.filter.selling_points.length === this.sellingPoints.length;

    if (isAllSelected) {
      this.filter.selling_points = [];
      return;
    }
    this.filter.selling_points = this.sellingPoints.map((it: any) => it.id);
  }

  public selectAllReasons() {
    const isAllSelected = this.filter.reasons.length === this.reasons.length;

    if (isAllSelected) {
      this.filter.reasons = [];
      return;
    }
    this.filter.reasons = this.reasons.map((it: any) => it.id);
  }

  public async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  public async getAllReasons(companyId: string) {
    const reasons = await reasonService.getAllReasons(companyId);
    this.reasons = reasons.map((it: any) => new Reason(it));
  }

  public generateReport() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .cancellationReason(this.filter.format())
      .then(({ data }: any) => {
        const winUrl = URL.createObjectURL(new Blob([data.template], { type: 'text/html' }));

        window.open(winUrl, '__blank');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public generateReportExcel() {
    if (!this.$refs.form.validate() || !this.filter.company_id) return;
    reportService
      .cancellationReasonExcel({ ...this.filter.format(), export: 'xls' })
      .then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octetstream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${new Date().getTime()}.xls`;
        link.click();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro.',
          color: 'danger',
        });
      });
  }

  public allowedDaysStart(value: string) {
    if (this.filter.end_date) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.end_date).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  public allowedDaysEnd(value: string) {
    if (this.filter.start_date) {
      return (
        moment(value, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.filter.start_date).format('YYYY-MM-DD'),
        ) && moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  resetForm() {
    this.filter.reasons = [];
    this.filter.selling_points = [];
    this.filter.start_date = '';
    this.filter.end_date = '';
  }

  public setCompanyId(companyId: string) {
    this.resetForm();
    this.filter.company_id = companyId;
    this.getAllReasons(companyId);
    this.getSellingPointsByCompanyId();
  }

  public async getSellingPointsByCompanyId() {
    const sellingPoints: Company[] = await sellingPointsService.getSellingPointByCompanyId(
      this.filter.company_id,
    );
    this.sellingPoints = sellingPoints.map(sellingPoint => new SellingPoint(sellingPoint));
  }

  public created() {
    this.filter.company_id = this.user.company_id;
    this.getCompanies();
    this.getAllReasons(this.filter.company_id);
    this.getSellingPointsByCompanyId();
  }
}
