import { StatusEnum } from '@/modules/Clients/components/form/enums/status.enum';
import {
  CancellationReasonGroupByEnum,
  ICancellationReasonFilter,
} from '../interfaces/cancellation-reason-filter.interface';

export class CancellationReasonFilter implements ICancellationReasonFilter {
  public company_id: string;

  public reasons: string[];

  public selling_points: string[];

  public groupBy: CancellationReasonGroupByEnum;

  public start_date: string;

  public end_date: string;

  constructor() {
    this.company_id = '';
    this.reasons = [];
    this.groupBy = CancellationReasonGroupByEnum.REASON;
    this.selling_points = [];
    this.start_date = '';
    this.end_date = '';
  }

  format() {
    return {
      group_by: this.groupBy ? this.groupBy : undefined,
      company_id: this.company_id ? this.company_id : undefined,
      selling_points: this.selling_points.length ? this.selling_points : undefined,
      reasons: this.reasons.length ? this.reasons : undefined,
      start_date: this.start_date ? this.start_date : undefined,
      end_date: this.end_date ? this.end_date : undefined,
      reason_type: StatusEnum.GIVE_UP,
    };
  }
}
