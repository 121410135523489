import http from '@/services/http.service';
import { AxiosResponse } from 'axios';
import { IContactOriginFilter } from '../interfaces/contact-origin-filter.interface';
import { IProductInterestFilter } from '../interfaces/product-interest-filter.interface';
import { IReminderCallFilter } from '../interfaces/reminder-call-filter.interface';
import { ISalesFunnelConvertionFilter } from '../interfaces/sales-funnel-convertion-filter.interface';
import { ISalesFunnelFilter } from '../interfaces/sales-funnel-filter.interface';
import { IUnitByEnterpriseFilter } from '../interfaces/unit-by-enterprise-filter.interface';
import { IVisitScheduleFilter } from '../interfaces/visit-schedule-filter.interface';

class ReportService {
  async productInterestGenerateReport(filter: IProductInterestFilter) {
    return http
      .get('/report/product-interest', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async clientsRegistred(filter: IProductInterestFilter) {
    return http
      .get('/report/orders-registered', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async clientsRegistredExcel(filter: IProductInterestFilter) {
    return http
      .get('/report/orders-registered', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async contactOrigin(filter: IContactOriginFilter) {
    return http
      .get('/report/channel', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async contactOriginExcel(filter: IContactOriginFilter) {
    return http
      .get('/report/channel', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async productInterestGenerateReportExcel(filter: IContactOriginFilter) {
    return http
      .get('/report/product-interest', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async unitByEnterprise(filter: IUnitByEnterpriseFilter) {
    return http
      .get('/report/unit', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async unitByEnterpriseExcel(filter: IUnitByEnterpriseFilter) {
    return http
      .get('/report/unit', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async unitsSoldGenerateReport(filter: IUnitByEnterpriseFilter) {
    return http
      .get('/report/unit-sold', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async unitsSoldGenerateReportExcel(filter: IUnitByEnterpriseFilter) {
    return http
      .get('/report/unit-sold', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async cancellationReason(filter: IUnitByEnterpriseFilter) {
    return http
      .get('/report/reason', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async cancellationReasonExcel(filter: IUnitByEnterpriseFilter) {
    return http
      .get('/report/reason', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async salesFunnelConversion(filter: ISalesFunnelConvertionFilter) {
    return http
      .get('/report/sales-funnel-convertion', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async salesFunnelConversionExcel(filter: ISalesFunnelConvertionFilter) {
    return http
      .get('/report/sales-funnel-convertion', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async salesFunnel(filter: ISalesFunnelFilter) {
    return http
      .get('/report/sales-funnel', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async salesFunnelExcel(filter: ISalesFunnelFilter) {
    return http
      .get('/report/sales-funnel', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async ordersVisit(filter: IVisitScheduleFilter) {
    return http
      .get('/report/orders-visit', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async ordersVisitExcel(filter: ISalesFunnelFilter) {
    return http
      .get('/report/orders-visit', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async reminderCalled(filter: IReminderCallFilter) {
    return http
      .get('/report/reminder-call', {
        params: {
          ...filter,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  async reminderCalledExcel(filter: IReminderCallFilter) {
    return http
      .get('/report/reminder-call', {
        params: {
          ...filter,
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }: AxiosResponse) => data);
  }
}

export default new ReportService();
